import Lottie from 'react-lottie';
import React, { useRef } from "react";
import { only } from "styled-breakpoints";
import styled, { css } from "styled-components";
import {
  H2,
  H3,
  H4,
  H5,
  Paragraph1,
  Paragraph2,
  Paragraph3,
  Center,
  Space1,
  Space2,
  Space3,
  Space4,
  Space5,
  PageContainer,
  RedDivider,
  SmallRedDivider,
  HideOnDesktop,
  HideOnMobile,
  Paragraph4,
} from "./Typography";
import Members from "./Members";
import Button from "./Button";
import arrow from "./img/assets/arrow.svg";
import asset1 from "./img/assets/asset_1.svg";
import asset2 from "./img/assets/asset_2.svg";
import asset4 from "./img/assets/asset_4.svg";
import asset5 from "./img/assets/asset_5.svg";
import ContactForm from "./ContactForm";
import animationData from './bg.json'

const ANIMATION_OPTIONS = {
  loop: true,
  autoplay: true, 
  animationData: animationData,
};

const Home = () => {
  const formContainerRef = useRef(null);

  const scrollToForm = () => {
    if (!formContainerRef.current) {
      return;
    }

    formContainerRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  return (
    <PageContainer>
      <Root>
        <PageBackground>
          <Lottie options={ANIMATION_OPTIONS} />
        </PageBackground>
        <Content>
          <Center>
            <span id="about" />
            <Space1 />
            <H2>We Are Science.</H2>
            <Space4 />
            <Paragraph1>
              GeoQuant uses advances in political and computer science to create
              high-frequency, systematic country risk data and analytics that
              are transparent and can be validated. Our geopolitical forecasts are highly
              accurate because they are built on robust models. When they’re
              wrong, you’ll know that, too.
            </Paragraph1>
            <Space4 />
            <H4>We let the data speak.</H4>
            <Space2 />
            <img src={arrow} alt="arrow" />
            <Space2 />
          </Center>
        </Content>
        <Content>
          <Center>
            <H2>You Want to Reduce Risk.</H2>
            <Space4 />
            <Paragraph1>
              You understand the value of models and systems, which is why you
              will welcome the revolution that combines political science and AI
              to measure and predict geopolitical risk at high-frequency.
            </Paragraph1>
          </Center>
        </Content>
        <Space1 />
        <span id="science" />
        <Space1 />
        <Content>
          <Section leftText="02." rightText="Science" />
          <Space2 />
          <Center>
            <Space3 />
            <H3>Our Approach</H3>
            <Space1 />
            <GridRow
              leftSide={
                <SmallTitledParagraph
                  title="We start with accepted “ground truths”"
                  text="in contemporary political science that define the core drivers of political change - making the fundamentals of political risk explicit for the first time."
                />
              }
              rightSide={<GridImage src={asset4} alt="" />}
            />
            <GridRow
              reverse
              leftSide={<GridImage src={asset2} alt="" />}
              rightSide={
                <SmallTitledParagraph
                  title="We use computer science to integrate those fundamentals"
                  text="into real-time assessments of governance, social and security conditions, drawn from the most credible data and media sources."
                />
              }
            />
            <GridRow
              leftSide={
                <SmallTitledParagraph
                  title="This data is systematic and adaptive,"
                  text="predicting commercial outcomes and directly connecting the dots between politics, economics and markets."
                />
              }
              rightSide={<GridImage src={asset5} alt="" />}
            />
            <GridRow
              reverse
              leftSide={<GridImage src={asset1} alt="" />}
              rightSide={
                <SmallTitledParagraph
                  title="Every geopolitical risk decision can now be data-based,"
                  text="and compared easily across time, issues, countries and regions."
                />
              }
            />
          </Center>
          <Center>
            <RedDivider />
            <Space1 />
            <ShortContent>
              <H3>Level-Setting: You Have To Change, Too</H3>
              <Space4 />
              <Paragraph3>
                Our data has never existed before. To incorporate it, you may
                need to make changes in your models, decision-making and work
                flows. We think it’s worth it. The investors, corporations,
                insurers and governments who have integrated our data agree.
                They went from skeptics to standard-bearers.
              </Paragraph3>
              <Paragraph3>
                In August 2022, Geoquant was acquired by Fitch Group and is now
                part of{" "}
                <ExtLink
                  href="https://www.fitchsolutions.com/press-releases/fitch-group-completes-acquisition-geoquant"
                  target="_blank"
                >
                  Fitch Solutions
                </ExtLink>
              </Paragraph3>
              <Space1 />
            </ShortContent>
          </Center>
          <Center>
            <Button onClick={scrollToForm}>Contact Us</Button>
            <Space1 />
          </Center>
          <span id="team" />
          <Space1 />
          <Section leftText="03." rightText="Team" />
          <Space1 />
          <Center>
            <Members />
          </Center>
          <Space1 />
          <span id="contact" />
          <Space1 />
          <Section leftText="04." rightText="Contact" />
          <div ref={formContainerRef}>
            <Space2 />
            <ContactForm source="contact_form" />
            <Space2 />
            <Address />
          </div>
          <Space1 />
        </Content>
      </Root>
    </PageContainer>
  );
};

export default Home;

const Address = () => {
  return (
    <div>
      <Center>
        <Paragraph3>GeoQuant</Paragraph3>
        <Space4 />
        <Paragraph4>33 Whitehall St</Paragraph4>
        <Space5 />
        <Paragraph4>New York, NY 10004</Paragraph4>
        <Space5 />
        <Paragraph4>United States</Paragraph4>
      </Center>
    </div>
  );
}

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Content = styled.div`
  max-width: 1080px;
`;


const GridRow = ({ leftSide, rightSide, reverse }) => {
  return (
    <StyledGridRow reverse={reverse}>
      <div style={{ flex: 2 }}>{leftSide}</div>
      <div style={{ flex: 1 }} />
      <div style={{ flex: 2 }}>{rightSide}</div>
    </StyledGridRow>
  );
};

const StyledGridRow = styled.div`
  display: flex;
  margin-bottom: 6rem;
  ${only("mobile")} {
    flex-direction: column;
    ${({reverse}) => reverse && css`
      flex-direction: column-reverse;

  `}
`

const Section = ({ leftText, rightText, id }) => {
  return (
    <StyledSection id={id}>
      <H5>{leftText}</H5>
      <RedDivider />
      <H5>{rightText}</H5>
    </StyledSection>
  );
};

const SmallTitledParagraph = ({ title, text }) => {
  return (
    <div style={{ textAlign: "left", marginTop: "5px" }}>
      <H5 style={{ display: "inline", marginRight: "1ch" }}>{title}</H5>
      <Paragraph2 style={{ display: "inline" }}>{text}</Paragraph2>
      <Space3 />
      <HideOnMobile>
        <SmallRedDivider />
      </HideOnMobile>
    </div>
  );
};

const GridImage = ({ src, alt }) => {
  return (
    <React.Fragment>
      <StyledGridImage src={src} alt={alt || ""} />
      <HideOnDesktop>
        <SmallRedDivider />
      </HideOnDesktop>
    </React.Fragment>
  );
};

const ExtLink = styled.a`
  color: #fff;
  font-weight: bold;
`

const StyledGridImage = styled.img`
    max-width: 100%;
    margin-bottom: 30px;
`

const PageBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  transition: opacity 0.5s ease;
`;
const ShortContent = styled.div``;

const StyledSection = styled.div`
  display: flex;
  align-items: center;
  ${only("mobile")} {
    justify-content: center;
  }
`;
