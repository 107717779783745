import styled from "styled-components";
import React from "react";
import { H4, Space1, PageContainer } from "./Typography";

const FormSubmitted = () => {
  return (
    <PageContainer>
      <Content>
        <Space1 />
        <H4>The paper you requested will be emailed to you shortly.</H4>
        <div style={{ minHeight: "60vh" }} />
      </Content>
    </PageContainer>
  );
};

const Content = styled.div`
  margin: 4rem 0 8rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export default FormSubmitted;
