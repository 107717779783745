import React from "react";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import { H4, Space1, PageContainer } from "./Typography";
import ReactPlayer from "react-player";
import useQuery from "./useQuery";

const Video = React.memo(() => {
  const params = useQuery();
   const url = params.get('url')
   const title = params.get('title')
  return (
    <PageContainer>
      <Content>
        <Space1 />
        <H4>{title}</H4>
        <Space1 />
        <PlayerContainer>
          <ReactPlayer url={url} controls width="100%" />
        </PlayerContainer>
        <Space1 />
      </Content>
    </PageContainer>
  );
});

const PlayerContainer = styled.div`
  width: 100%;
`;

const Content = styled.div`
  max-width: 800px;

  ${up("desktop")} {
    margin-left: 180px;
  }
`;
export default Video;
