import React, { useCallback, useState } from "react";
import { Center, Input } from "./Typography";
import Button from "./Button";
import axios from "axios";
import { isEmpty, flow, map, keyBy, mapValues } from "lodash/fp";
import styled, { css } from "styled-components";

// the name attributes here must be as they are since they need to match what the backend expects
const FORM_INPUT_FIELDS = [
  {
    name: "name",
    type: "text",
    placeholder: "Name",
  },
  {
    name: "title",
    type: "text",
    placeholder: "Title",
  },
  {
    name: "email",
    type: "email",
    placeholder: "Email",
  },
  {
    name: "company",
    type: "text",
    placeholder: "Company",
  },
  {
    name: "phone",
    type: "text",
    placeholder: "Phone",
  },
  {
    name: "nature_of_inquire",
    type: "text",
    placeholder: "Nature of Inquiry",
  },
];

const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const formServerErrorMsg = `We’re sorry but something went wrong, You can try again or contact us at info@geoquant.com`;

const getFormSubmissionURL = () => {
  let targetURL = "";
  const siteLocation = document.URL;
  if (siteLocation.includes("stg.geoquant")) {
    // STAGING
    targetURL = "https://app-dev.stg127-int.k.geoquant.io/api/webapp_api/contact_form";
  } else if (siteLocation.includes("localhost")) {
    targetURL = "https://app-dev.stg127-int.k.geoquant.io/api/webapp_api/contact_form";
  } else if (siteLocation.includes("geoquant.com")) {
    // PRODUCTION
    targetURL = "https://app.geoquant.com/api/webapp_api/contact_form";
  }
  return targetURL;
};

export const postContactForm = (formData, source) => {
  return axios.post(getFormSubmissionURL(), { ...formData, source });
};

const ContactForm = React.memo(
  ({ onSubmitSuccess, extraData, source, shouldAcceptTerms }) => {
    const [formState, setFormState] = useState({ show: false });
    const [formData, setFormData] = useState(
      flow(
        map((field) => field.name),
        keyBy((field) => field),
        mapValues((_) => "")
      )(FORM_INPUT_FIELDS)
    );
    const [didAcceptTerms, setDidAcceptTerms] = useState(false);
    const toggleTerms = () => setDidAcceptTerms((curr) => !curr);

    const hideFormMessage = useCallback(() => {
      setFormState((current) => ({ ...current, show: false }));
    }, []);

    const showFormMessage = useCallback(
      (type, text, timeout = 7500) => {
        setFormState({ show: true, type, text });
        setTimeout(hideFormMessage, timeout);
      },
      [hideFormMessage]
    );

    const handleInputChange = useCallback(
      (event) => {
        const { value, name } = event.target;
        setFormData((formData) => ({ ...formData, [name]: value }));
        hideFormMessage();
      },
      [hideFormMessage]
    );

    const validateFormAndGetMissingFields = useCallback(() => {
      const missingFields = [];
      for (const field of FORM_INPUT_FIELDS) {
        const fieldValue = formData[field.name];
        if (
          !fieldValue ||
          fieldValue === "" ||
          (field.type === "email" &&
            !EMAIL_REGEX.test(fieldValue.toLowerCase()))
        ) {
          missingFields.push(field.placeholder);
        }
      }
      return missingFields;
    }, [formData]);

    const onFormSubmit = useCallback(() => {
      const invalidFields = validateFormAndGetMissingFields();
      if (!isEmpty(invalidFields)) {
        showFormMessage(
          "error",
          `Please properly fill the following fields: ${invalidFields.join(
            ", "
          )}`
        );
        return;
      }

      if (shouldAcceptTerms && !didAcceptTerms) {
        showFormMessage("error", "Please accept our terms", 10000);
        return;
      }

      const formWithExtraData = {
        ...formData,
        extraData,
      };
      postContactForm(formWithExtraData, source)
        .then((res) => {
          if (!res.data) {
            showFormMessage("error", formServerErrorMsg, 15000);
            return;
          }

          onSubmitSuccess(formWithExtraData);
          showFormMessage("success", "Inquiry received, thank you.", 15000);
        })
        .catch(() => showFormMessage("error", formServerErrorMsg, 15000));
    }, [
      validateFormAndGetMissingFields,
      showFormMessage,
      formData,
      onSubmitSuccess,
      extraData,
      source,
      shouldAcceptTerms,
      didAcceptTerms,
    ]);

    return (
      <Center>
        <Form id="contact-form">
          <Center>
            {FORM_INPUT_FIELDS.map((field) => {
              const { name, type, placeholder } = field;
              const fieldValue = formData[name];
              return (
                <InputContainer key={name}>
                  <Label visible={!isEmpty(fieldValue)}>{placeholder}</Label>
                  <Input
                    style={{ width: "100%" }}
                    name={name}
                    type={type}
                    placeholder={placeholder}
                    value={fieldValue}
                    onChange={handleInputChange}
                  />
                </InputContainer>
              );
            })}
            {shouldAcceptTerms && (
              <InputContainer>
                <Terms>
                  Unauthorized redistribution of this report is prohibited. By
                  checking this box, you agree you will not use this report, any
                  material or data contained herein for any commercial purpose
                  without a separate license agreement with GeoQuant Inc.
                </Terms>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Input
                    name="terms"
                    type="checkbox"
                    checked={didAcceptTerms}
                    onChange={toggleTerms}
                    style={{ marginRight: 12 }}
                  />
                  <Label
                    visible
                    onClick={toggleTerms}
                    style={{ userSelect: "none" }}
                  >
                    I Agree to the above Terms & Conditions
                  </Label>
                </div>
              </InputContainer>
            )}
            <FormMessageContainer visible={formState.show}>
              <FormMessage type={formState.type}>{formState.text}</FormMessage>
            </FormMessageContainer>
            <Button onClick={onFormSubmit}>Send</Button>
          </Center>
        </Form>
      </Center>
    );
  }
);

ContactForm.defaultProps = {
  onSubmitSuccess: () => {},
  extraData: {},
};

const Form = styled.form`
  width: 100%;
  padding-bottom: 16px;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  width: 80%;
  max-width: 460px;
`;

const Label = styled.label`
  font-size: 14px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  color: rgba(169, 171, 191, 0.8);
  ${(props) =>
    props.visible &&
    css`
      opacity: 1;
    `}
`;

const Terms = styled.div`
  font-size: 12px;
  color: rgba(169, 171, 191, 0.8);
  padding-bottom: 20px;
  text-align: left;
`;
const FormMessageContainer = styled.div`
  min-height: 50px;
  margin-bottom: 0.5rem;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  ${(props) =>
    props.visible &&
    css`
      opacity: 1;
    `}
`;

const FormMessage = styled.div`
  padding: 4px 8px;
  color: white;
  border-radius: 4px;
  ${(props) =>
    props.type === "error" &&
    css`
      background: #ff6363;
    `}
  ${(props) =>
    props.type === "success" &&
    css`
      background: #058439;
    `}
`;

export default ContactForm;
