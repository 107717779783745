import React from "react";
import styled from "styled-components";

export const Button = React.memo((props) => {
  return (
    <ButtonContainer onClick={props.onClick}>
      <ButtonGhost>
        <ButtonGhostBg />
        <span>{props.children}</span>
      </ButtonGhost>
    </ButtonContainer>
  );
})

export default Button;

const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  border: solid 1px #ff6363;
  color: #ff6363;
  width: 210px;
  height: 54px;
  white-space: nowrap;
  cursor: pointer;
`;

const ButtonGhost = styled.div`
  width: 210px;
  height: 54px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-10px, 10px);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  border: solid 1px #ff6363;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.5s ease-out;
  &:hover {
    transform: translate(0, 0);
    color: #fff;
  }
`;

const ButtonGhostBg = styled.div`
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateY(54px);
  background-color: #ff6363;
  transition: all 0.5s ease-out;
  ${ButtonGhost}:hover  & {
    transform: translateY(0);}
  }
`;
