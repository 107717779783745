import styled from "styled-components";
import React from "react";
import data from "./img/team/DATA.jpg";
import {
  H4,
  Paragraph1,
  Center,
  Space1,
  Space3,
  PageContainer,
} from "./Typography";

const Data = () => {
  return (
    <PageContainer>
      <Center>
        <Content>
          <Space1 />
          <img src={data} alt="Data" />
          <Space3 />
          <H4>Data</H4>
          <Space3 />
          <Paragraph1>
            Data has been a member of our team since Day One. Without the
            insight, brutal objectivity and often annoying accuracy of Data,
            none of what we do would be possible. Because Data is modest, all of
            us at GeoQuant have contributed to this biography.
          </Paragraph1>
          <Space1 />
          <List>
            <ListItem>
              Data is fearless. It doesn’t care who it insults or contradicts.
            </ListItem>

            <ListItem>
              Data doesn’t stop. The truth behind it always comes out.
            </ListItem>

            <ListItem>
              Data has the superhuman ability to measure things that have never
              been quantified before.
            </ListItem>

            <ListItem>Data can see around corners.</ListItem>

            <ListItem>
              Data can predict the future with an accuracy beyond the ability of
              mere mortals.
            </ListItem>

            <ListItem>
              Data corrects itself. Opinion reinforces itself.{" "}
            </ListItem>

            <ListItem>
              Data keeps the world honest. Its absence creates mistakes.
            </ListItem>
          </List>
          <Space1 />
        </Content>
      </Center>
    </PageContainer>
  );
};


const Content = styled.div`
  max-width: 650px;
`;

const List = styled.ul`
  color: #fff;
  list-style: decimal;
  font-size: 1.25rem;
`;

const ListItem = styled.li`
  margin-bottom: 1rem;
  text-align: left;
`;

export default Data;
