import styled from "styled-components";
import React from "react";
import {
  H2,
  H4,
  Center,
  Space1,
  Space2,
  Space4,
  PageContainer,
  Paragraph1,
} from "./Typography";
import arrow from "./img/assets/arrow.svg";

const IFRAME_URL =
  "https://app-dev.stg127-int.k.geoquant.io/#/quick-login/619f4e00c5215dcda397bb20";

const Embed = React.memo(() => {
  return (
    <PageContainer>
      <Root>
        <Content>
          <Center>
            <Space1 />
            <H2>iframe Test Page</H2>
            <Space1 />
            <Space1 />
            <Paragraph1>
              GeoQuant uses advances in political and computer science to create
              high-frequency, systematic country risk data and analytics that
              are transparent and can be validated. Our geopolitical forecasts
              are highly accurate because they are built on robust models. When
              they’re wrong, you’ll know that, too.
            </Paragraph1>
            <Space4 />
            <H4>We let the data speak.</H4>
            <Space2 />
            <img src={arrow} alt="arrow" />
            <Space2 />
          </Center>
        </Content>
        <Content>
          <Center>
            <H2>You Are Truth-Seekers.</H2>
            <H2>You Want to Reduce Risk By Eliminating Bull.</H2>
            <Space4 />
            <Paragraph1>
              You understand the value of models and systems, which is why you
              can’t wait to eliminate reliance on hand-waving pundits. You will
              welcome the revolution that combines political science and AI to
              measure and predict geopolitical risk at high-frequency.
            </Paragraph1>
            <Space1 />
            <img src={arrow} alt="arrow" />
            <Space1 />
          </Center>
        </Content>
        <div style={{ minHeight: "calc(100vh - 100px)", width: "100%" }}>
          <iframe
            title="iframe test"
            style={{ height: "calc(100vh - 100px)", width: "100%", border: 0 }}
            src={IFRAME_URL}
          />
        </div>
      </Root>
    </PageContainer>
  );
});

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 90vw;
`;
const Content = styled.div`
  max-width: 1080px;
`;

export default Embed;
