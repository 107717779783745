import React from "react";
import styled from "styled-components";
import logo from "./img/Geoquant_Logo_Digital_White.svg";
import { Link as ReactRouterLink } from "react-router-dom";
import { only, up } from "styled-breakpoints";

export const H2 = styled.h2`
  font-size: 2.5rem;
  color: #fff;
`;

export const H3 = styled.h3`
  font-size: 2rem;
  color: #fff;
`;

export const H4 = styled.h4`
  font-size: 1.5rem;
  color: #fff;
`;

export const H5 = styled.h5`
  font-size: 1.25rem;
  color: #fff;
`;

export const H6 = styled.h6`
  font-size: 1rem;
  color: #fff;
`;

export const Paragraph1 = styled.p`
  font-size: 1.5rem;
  color: rgba(169, 171, 191, 0.8);
  line-height: 1.6;
`;

export const Paragraph2 = styled.p`
  font-size: 1.25rem;
  color: rgba(169, 171, 191, 0.8);
  line-height: 1.6;
`;
export const Paragraph3 = styled.p`
  font-size: 1rem;
  color: rgba(169, 171, 191, 0.8);
  line-height: 1.6;
`;

export const Paragraph4 = styled.p`
  font-size: .8rem;
  color: rgba(169, 171, 191, 0.8);
  line-height: 1.6;
`;


export const Link = styled.a.attrs(props => ({
  ...props
}))`
  color: rgba(255, 255, 255, 0.6);
  position: relative;
  text-transform: uppercase;
  font-size: 1rem;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: #fff;
    &:after {
      transform: scaleX(1);
    }
  }

  &:after {
    content: "";
    position: absolute;
    left: -5%;
    right: -5%;
    height: 2px;
    background-color: #ff6363;
    transition: all 0.5s ease-in-out;
    bottom: -20%;
    transform: scaleX(0);
  }
`;

export const UnstyledLink = styled.a`
  text-decoration: none;
`;
export const RouterLink = styled(ReactRouterLink)`
  text-decoration: none;
`;

export const Center = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Logo = () => <LogoImage src={logo} alt="GEOQUANT" />;

const LogoImage = styled.img`
  width: 180px;
`;

export const Space1 = styled.div`
  height: 6rem;
`;

export const Space2 = styled.div`
  height: 4rem;
`;

export const Space3 = styled.div`
  height: 2rem;
`;

export const Space4 = styled.div`
  height: 1rem;
`;

export const Space5 = styled.div`
  height: .5rem;
`;

export const HSpace4 = styled.div`
  width: 1rem;
`;

export const Input = styled.input`
  background-color: rgba(0,0,0,0);
  border-width: 0 0 1px 0;
  border-color: #ff6363;
  font-size: 20px;
  line-height: 1.5;
  color: white;
`;

export const RedDivider = styled.div`
  margin: 0 2rem;
  max-width: 110px;
  width: 100%;
  height: 2px;
  background-color: #ff6363;
`;

export const SmallRedDivider = styled.div`
  margin: 0;
  width: 60px;
  height: 1px;
  background-color: #ff6363;
`;


export const PageContainer = styled.div`
  padding: 0 2.5rem;
  width: 100%;
  ${only("mobile")} {
    padding: 0 1.5rem;
  }
`;

export const HideOnMobile = styled.div`
  ${only("mobile")} {
    display: none;
  }
`;

export const HideOnDesktop = styled.div`
  ${up("desktop")} {
    display: none;
  }
`
