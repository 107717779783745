// https://medium.com/better-programming/usebreakpoint-hook-get-media-query-breakpoints-in-react-3f1779b73568

import { useState, useEffect } from "react";
import { throttle } from "lodash";

const getDeviceConfig = (width) => {
  if (width <= 425) {
    return "mobile";
  } else if (width >= 425 && width < 768) {
    return "tablet";
  }

  return "desktop";
};

const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState(() =>
    getDeviceConfig(window.innerWidth)
  );

  useEffect(() => {
    const calcInnerWidth = throttle(function () {
      setBreakpoint(getDeviceConfig(window.innerWidth));
    }, 200);

    window.addEventListener("resize", calcInnerWidth);
    return () => window.removeEventListener("resize", calcInnerWidth);
  }, []);

  return breakpoint;
};
export default useBreakpoint;
