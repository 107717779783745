import React from "react";
import styled from 'styled-components'
import {
  Logo,
  Space1,
  Space2,
  Paragraph4,
  Center,
} from "./Typography";

const Footer = () => {
      return (
        <Root>
          <Center>
            <Space1 />
            <Logo />
            <Space1 />
            <Paragraph4>©{new Date().getFullYear()} Geoquant all right reserved</Paragraph4>
            <Space2 />
          </Center>
        </Root>
      );
}

const Root = styled.div`
  background-color: #071a30;
  width: 100%;
`;

export default Footer;
