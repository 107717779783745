import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "./Typography";
import { only } from "styled-breakpoints";

const ANCHOR_REGEX = /#(.*)/;

// Smooth Scroll by - https://medium.com/the-coders-guide-to-javascript/smooth-scrolling-anchor-menu-in-reactjs-175030d0bce2
const HeaderLink = ({ scrollItemId, href, text, icon, target }) => {
  const [anchorTarget, setAnchorTarget] = useState(null);

  useEffect(() => {
    const scrollItemId = ANCHOR_REGEX.test(href)
      ? href.match(ANCHOR_REGEX)[1]
      : null;
    if (scrollItemId) {
      setAnchorTarget(document.getElementById(scrollItemId));
    }
  }, [href]);

  const handleClick = (event) => {
    if (anchorTarget) {
      event.preventDefault();
      anchorTarget.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <Root>
      <Link href={href} onClick={handleClick} target={target}>
        {text && text}
        {icon && <Icon src={icon} />}
      </Link>
    </Root>
  );
};

const Root = styled.div`
  &:not(last-child) {
    margin-right: 2rem;
  }

  ${only("mobile")} {
        margin: 20px 0;
        a {
          color: white;
          font-size: 25px;
        }
      }
    }
`;

const Icon = styled.img`
  width: 32px;
  height: auto;
`;

HeaderLink.defaultProps = {
  target: "_self",
};

export default HeaderLink;
